@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700&display=swap');

body {
    font-family: 'Roboto', sans-serif;
}

h1 {
    font-size: 25px;
    font-weight: 300;
}

h2 {
    font-size: 20px;
    font-weight: 300;
}

h3 {
    font-size: 16px;
    font-weight: 400;
}

h4 {
    font-weight: 300;
}

p {
    font-weight: 300;
}

.Button {
    background-color:#0A1612;
    border-radius: 10px;
    border-color: #0A1612;
    color: white;
}

.Button:hover {
    background-color: #606161;
    border-color: #606161;
}

.Button:focus:active {
    background-color: #606161;
    border-color: #606161;
}

.Button-Yellow {
    background-color:#F7B733;
    border-radius: 10px;
    border-color: #F7B733;
    color: 'black';
}

.Button-Yellow:hover {
    background-color: #946e20;
    border-color: #946e20;
}

.Button-Yellow:focus:active {
    background-color: #946e20;
    border-color: #946e20;
}

.flex-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.flex-box {
    display: block;
}

.justify-start {
    justify-content: start;
}

.justify-around {
    justify-content: around;
}