.Card1--Image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
    max-height: 300px;
}

.Card1--Container {
    width: 50%;
    height: 100%;
    min-height: 200px;
    padding-right: 20px;
}

.Card1--Title {
    font-weight: 600;
    font-size: 22px;
}

.Card1--Description {
    font-weight: 400;
    font-size: 14px;
    margin-bottom: 40px;
    line-height: 24px;
}


@media only screen and (max-width: 600px) {
    .Card1--Container {
        width: 100%;
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .Card1--Description {
        margin-bottom: 20px;
    }
}