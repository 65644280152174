.container {
    width: 100%;
    max-width: 1000px;
    display: flex;
    padding: 20px;
}

.d-flex {
    display: flex;
}

.flex-row {
    flex-direction: row;
}

.flex-wrap {
    flex-wrap: wrap;
}

.justify-content-center {
    justify-content: center;
}

.justify-space-around {
    justify-content: space-around;
}

.justify-space-between {
    justify-content: space-between;
}

.align-items-center {
    align-items: center;
}

.align-items-start {
    align-items: flex-start;
}

.align-items-end {
    align-items: end;
}

.p-5 {
    padding: 50px;
}
.p-4 {
    padding: 40px;
}
.p-3 {
    padding: 30px;
}
.p-2 {
    padding: 20px;
}
.p-1 {
    padding: 10px;
}
.w-100 {
    width: 100%;
}


/* BUTTONS */
.Button__Outlined {
    color: black;
    padding: 10px 20px 10px 20px;
    border-radius: 10px;
    border: 2px solid black;
    font-weight: 600;
}

.Button__Outlined:hover {
    animation: 0.3s linear;
    animation-name: buttonColourChange;
    animation-fill-mode: forwards;
    cursor: pointer;
}

@keyframes buttonColourChange {
    0% {
        background-color: white;
        color: black;
    }
    100% {
        background-color: black;
        color: white;
    }
}

@media only screen and (max-width: 600px) {
    .container {
        padding: 10px;
    }
}