.Logo-Image {
    height: 120px;
    max-height: 100%;
}

.Logo-Text {
    letter-spacing: 5px;
    font-size: 50px;
    font-weight: 200;
}

@media only screen and (max-width: 576px) {
    .Logo-Text {
        letter-spacing: 5px;
        font-size: 40px;
        font-weight: 200;
    }
    .Logo-Image {
        height: 80px;
    }

}