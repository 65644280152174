.Aumthenticus-VideoBanner {
    width: 100%;
    min-height: 500px;
    object-fit: cover;
    object-position: center;
}

.Aumthenticus-Page-Gallery-Image {
    width: 100%;
    height: 300px;
    object-position: center;
    object-fit: cover;
}

.Aumthenticus-Image {
    width: 400px;
    height: 300px;
    object-position: center;
    object-fit: cover;
}