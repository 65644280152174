.Banner3--Container {
    width: 100%;
    height: 90vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.Banner3--Left {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
}

.Banner3--Right {
    width: 50%;
    height: 100%;
    object-fit: cover;
    display: inline-block;
}

.Banner3--Image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.Banner3--TextContainer {
    width: 100%;
    padding: 20px;
}

.Banner3--Title {
    font-style: italic;
    font-weight: 700;
    font-size: 50px;
    margin-bottom: 10px;
}

.Banner3--Description {
    font-style: italic;
    font-size: 30px;
    line-height: 40px;
}

@media only screen and (max-width: 600px) {
    .Banner3--Container {
        width: 100%;
        min-height: 90vh;
    }

    .Banner3--Left {
        width: 100%;
        min-height: 50vh;
    }

    .Banner3--Right {
        width: 100%;
        min-height: 50vh;
    }

    .Banner3--Image {
        width: 100%;
    }

    .Banner3--TextContainer {
        width: 100%;
        padding: 20px;
    }

    .Banner3--Title {
        font-style: italic;
        font-weight: 700;
        font-size: 30px;
        margin-bottom: 10px;
    }

    .Banner3--Description {
        font-style: italic;
        font-size: 20px;
        line-height: 28px;
    }
}