@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap');

.AboutMe-Row {
    font-family: "Open Sans";
    background-color: white;
}

.AboutMe2-Image {
    height: 50vh;
    width: 100%;
    object-fit: cover;
    object-position: center;
    text-align: center;
    border-radius: 5px;
}

.AboutMe2-Name {
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 40px;
    margin-bottom: 0px;
}

.AboutMe2-Title {
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 41px;
}

@media only screen and (max-width: 600px) {
    .AboutMe2-Image {
        height: 60vh;
    }
}