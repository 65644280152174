.Pricing-Logo {
    max-height: 70px;
    max-width: 70px;
}

.Pricing-Phros-Name {
    font-size: 50px;
    font-weight: 200;
    letter-spacing: 2px;
}

.PricingCard-Div {
    background: #FFFFFF;
    box-sizing: border-box;
    box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: 30px;
    height: 100%;
    width: 100%;
}

.PricingCard-PlanName {
    font-style: normal;
    font-weight: bold;
    font-size: 23px;
    line-height: 29px;
    color: #000000;
    text-align: center;
}

.PricingCard-PlanDescription {
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 21px;
    text-align: center;
}

.Pricing-PlanDescription {
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 21px;
}


.PricingCard-Pricing {
    font-style: normal;
    font-weight: 400;
    font-size: 34px;
    line-height: 47px;
    text-align: center;
}

.PricingCard-PriceDuration {
    font-style: normal;
    font-weight: 200;
    font-size: 23px;
    line-height: 29px;
}

.PricingCard-Price {
    display: inline;
}

.PriceCard-YellowLine {
    width: 100%;
    height: 10px;
    left: 142px;
    top: 423px;
    background: #F7B733;
}

.PricingCard-Button {
    background: #0A1612;
    border: 0.2px solid #000000;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    color: white;
    width: 100%;
    height: 35px;
    font-weight: 400;
    font-size: 18px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.PricingCard-Button:hover {
    background: #232424;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.85);
    cursor: pointer;
}

.PricingCard-Features {
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 29px;
}

.PricingCard-FeatureList {
    font-style: normal;
    font-weight: 200;
    font-size: 16px;
    line-height: 21px;
}

.Pricing-Main-Div {
    padding-bottom: 20px;
}