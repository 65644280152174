.Contact2-Input {
    border-radius: 5px;
    font-family: "Open Sans";
    font-style: italic;
    font-weight: 500;
}

.Contact2-Title {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 30px;
}

.Contact2-Contact-Me {
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 6px;
    padding-bottom: 6px;
    background-color: black;
    color: white;
    border-radius: 10px;
    border: none;
}

.Contact2-Contact-Me:hover {
    color: white;
    opacity: 80%;
    cursor: pointer;
}

.Contact2-Divider {
    color: black;
    background-color: black;
}

.Contact2-HR {
    border-top: 1px solid black;
    background-color: black;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 20px;
}