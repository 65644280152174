.Service3-Image {
    background-color: black;
    opacity: 70%;
    object-fit: cover;
    height: 100%;
    width: 100%;
}

.Service3-Card {
    background-color: black;
    position: relative;
    color: white;
}

.Service3-Text-Div {
    color: white;
    padding: 35px;
    font-family: "Open Sans";
}

.Service3-Title {
    color: white;
    opacity: 100%;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
}

.Service3-Description {
    color: white;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 24px;
}

.Service-Button-Inverted {
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 8px;
    padding-bottom: 8px;
    background-color: white;
    color: black;
    border: none;
    border-radius: 10px;
}

.Service-Button-Inverted:hover {
    color: black;
    opacity: 60%;
}