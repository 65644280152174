.SignUp-Image-Col {
    min-height: 100vh;
}

.SignUp-Image {
    background-image: url('../../Assets/zen-yoga.jpg');
    background-size: cover;
    background-position: center;
    height: 100%;
    width: 100%;
}

.SignUp-Logo {
    height: 120px;
}

.SignUp-Info-Col {
    text-align: center;
}

.SignUp-Col {
    padding-top: 30px;
}

.SignUp-Col-Spacing {
    margin-bottom: 10px;
}

.SignUp-Button-Col {
    margin-top: 10px;
    margin-bottom: 10px;
}

.SignUp-FreeTrial-Text {
    margin-top: 20px;
    font-weight: 600;
}

.SignUp--Wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.SignUp--Container {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 90vw;
    max-width: 1400px;
    flex-wrap: wrap;
}

.SignUp--Left {
    width: 40%;
    min-height: 90vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.SignUp--Right {
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.SignUp--Image {
    max-width: 300px;
    height: 100%;
    object-fit: contain;
}

.SignUp--Phros {
    font-style: normal;
    font-weight: 300;
    font-size: 70px;
    line-height: 75px;
    margin-top: 10px;
}

.SignUp--Catch {
    font-size: 18px;
    line-height: 25px;
    font-weight: 300;
}
.SignUp--Catch--Span {
    font-weight: 600;
}

.SignUp--Input {
    margin-top: 10px;
    margin-bottom: 10px;
    border-radius: 0px !important;
    border-bottom: 1px solid black !important;
    padding-left: 0px !important;
}

.SignUp--Action {
    background: #000000;
    border-radius: 5px;
    border: none;
    color: white;
    width: 100%;
    height: 35px;
    margin-top: 20px;
}
.SignUp--Action {
    cursor: pointer;
}

@media only screen and (max-width: 600px) {
    .SignUp--Left {
        width: 100%;
        min-height: 30vh;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .SignUp--Right {
        width: 100%;
    }

    .SignUp--Image {
        max-height: 100px;
    }

    .SignUp--Phros {
        font-size: 50px;
        line-height: 65px;
    }

    .SignUp--Catch {
        text-align: center;
    }
}