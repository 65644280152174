.SiderText {
    color: rgba(255, 255, 255, 0.65);
}
.SiderText:hover {
    color: white;
}
.ModalElement {
    border: 1px solid black;
    border-radius: 5px;
    padding: 20px;
    margin-bottom: 10px;
    margin-top: 10px;
    position: relative;
}
.ModalElement:hover {
    cursor: pointer;
}
.ModelElement-Cover {
    background-color: transparent;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
}
.WebElement--Buttons {
    position: relative;
    display: none;
}
.WebElement--Div:hover .WebElement--Buttons {
    cursor: pointer;
    display: block;
    z-index: 1;
}
.WebElement--Div:hover {
    border: 1px solid black;
    border-radius: 5px;
}
.WebElement--Edit {
    position: absolute;
    top: 10px;
    right: 40px;
}
.WebElement--Delete {
    position: absolute;
    top: 10px;
    right: 5px;
}