.FAQ2--FAQ__Box {
    padding: 15px;
    cursor: pointer;
}

.FAQ2--Wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}

.FAQ2--Title {
    font-weight: 700;
}

.FAQ2--Container {
    width: 80vw;
    max-width: 1000px;
}

.FAQ2--FAQ__Question {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    font-style: italic;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.FAQ--Icon {
    max-width: 30px;
    max-height: 30px;
    color: black;
}

.FAQ2--FAQ__Answer {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 30px;
    padding-top: 20px;
    padding-bottom: 20px;
}

.FAQ2--Row {
    margin-top: 20px;
    margin-bottom: 20px;
    font-family: "Open Sans";
    padding-left: 20px;
    padding-right: 20px;
}

@media only screen and (max-width: 600px) {
    .FAQ2--Row {
        padding-left: 10px;
        padding-right: 10px;
    }
    .FAQ2--FAQ__Answer {
        padding: 15px;
    }
    .FAQ2--Container {
        width: 95vw;
    }
    .FAQ2--Title {
        font-weight: 700;
        font-size: 20px;
    }
}