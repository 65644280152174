.PreviewWebsite-Div {
    padding-top: 70px;
}

.PreviewWebsite-Div-First {
    padding-top: 100px;
}

.PreviewWebsite-Div-Last {
    padding-top: 70px;
    padding-bottom: 100px;
}

.PreviewWebsite-Max-Col {
    max-width: 1000px;
}