.AddLiveClass-Row {
    margin-top: 20px;
}

.AddLiveClass-Input {
    width: 100%;
}

.AddLiveClass-Availability-Row {
    padding-top: 30px;
    padding-bottom: 30px;
}

.AddLiveClass-Save-Buttons {
    margin-top: 20px;
    margin-bottom: 20px;
}

.AddLiveClass-Availability-Delete {
    height: 100%;
    width: 100%;
}