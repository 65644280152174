.Navbar3-Main-Col {
    background-color: white;
    padding: 10px;
    font-family: "Open Sans";
}

.Navbar3-Second-Bar {
    background-color: #E9E9E9;
    padding-top: 10px;
    padding-bottom: 10px;
}