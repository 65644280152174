.Profile-Row {
    margin-top: 30px;
}

.Profile-ProfilePicture {
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: 200px;
    object-fit: cover;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
}

.Profile-LogoPicture {
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: 200px;
    object-fit: contain;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
}

.Profile-Select {
    width: 100%;
}

.Profile-SocialMedia-Items {
    margin-top: 5px;
    margin-bottom: 5px;
}

.Profile-Colour-Div {
    height: 50px;
    width: 50px;
}

.Profile-Colour-Outer-Div {
    margin-bottom: 20px;
    display: block;
}