.Program-Week-Day {
    width: 100%;
    min-height: 100px;
    background: #FFFFFF;
    border: 0.2px solid #000000;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.Program-Week-Day-Add {
    height: 100px;
}

.Program-Week-Day-Delete {
    margin-left: 5px;
    width: 100%;
    min-height: 100px;
}

.Program-Workout-Planner-Large-Action {
    width: 100%;
    min-height: 200px;
    font-size: 24px;
    font-weight: bold;
    border: 0.2px solid #000000;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
}


.Workout-Tag {
    margin-top: 4px;
    margin-bottom: 4px;
    padding: 3px;
    min-width: 30px;
    min-height: 20px;
}
.Workout-Tag:hover {
    cursor: pointer
}

.Program-Workout-Exercise {
}

.Program-Create-Superset {
    background-color: black;
    color: white;
    border-radius: 10px;
}

.Program-Exercise-Row {
    margin-top: 10px;
    margin-bottom: 10px;
}

.Program-Workout-Save-Workout {
    border-radius: 10px;
    background-color: black;
    color: white;
}

.Program-Workout-Delete-Workout {
    border-radius: 10px;
}

.Program-Workout-Name {
    font-size: 15px;
    font-weight: bold;
    margin-left: 10px;
}

.Program-Input-Width {
    width: 100%;
}