@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap');

.Banner1-Main-Col {
    background-color: white;
    font-family: "Open Sans";
}

.Banner1-Img {
    width: 100%;
    height: 90vh;
    object-fit: cover;
    object-position: center;
    opacity: 0.6;
}

.Banner1-Main-Div {
    background-color: black;
    position: relative;
}

.Banner1-Text-Div {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width: 500px;
}

.Banner1-FirstName {
    text-align: left;
    color: white;
    font-style: italic;
    font-weight: 800;
    font-size: 70px;
    margin-bottom: 10px;
    line-height: 65px;
    word-wrap: break-word;
}

.Banner1-LastName {
    text-align: left;
    color: white;
    font-style: italic;
    font-weight: 800;
    font-size: 70px;
    margin-bottom: 10px;
    line-height: 65px;
    word-wrap: break-word;
}

.Banner1-BusinessStatement {
    text-align: left;
    color: white;
    font-style: italic;
    font-weight: 600;
    font-size: 25px;
    line-height: 35px;
    max-width: 700px;
    margin-top: 10px;
    word-wrap: break-word;
}

.Banner1-Input {
    border-radius: 10px;
    padding: 7px;
}

.Banner1-Contact-Button {
    background: #000000;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    border: black;
    color: white;
    height: 100%;
    width: 100%;
    padding: 7px;
}

.Banner1-Contact-Section {
    width: 100%;
}

@media only screen and (max-width: 600px) {
    .Banner1-Text-Div {
        min-width: 0px;
    }
}

