.Profile1--Container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
}

.Profile1--TextContainer {
    display: flex;
    flex-direction: column;
    justify-content: start;
    width: 50%;
    align-items: flex-start;
    min-width: 50%;
}

.Profile1--Title {
    font-size: 24px;
    font-weight: 700;
}

.Profile1--Subtitle {
    font-size: 20px;
    font-weight: 500;
}

.Profile1--Paragraph {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
}

.Profile1--ImageContainer {
    width: 50%;
    height: 100%;
    object-fit: contain;
}

.Profile1--Image {
    object-fit: contain;
    max-height: 500px;
    width: 100%;
}

.Profile1--Button {
    color: black;
    padding: 10px 20px 10px 20px;
    border-radius: 10px;
    border: 2px solid black;
    font-weight: 600;
    margin-bottom: 10px;
}

.Profile1--Button:hover {
    animation: 0.3s linear;
    animation-name: buttonColourChange;
    animation-fill-mode: forwards;
    cursor: pointer;
}

@keyframes buttonColourChange {
    0% {
        background-color: white;
        color: black;
    }
    100% {
        background-color: black;
        color: white;
    }
}

@media only screen and (max-width: 600px) {
    .Profile1--TextContainer {
        width: 100%;
    }

    .Profile1--ImageContainer {
        width: 100%;
    }

    .Profile1--Title {
        font-size: 22px;
        font-weight: 700;
    }

    .Profile1--Subtitle {
        font-size: 18px;
        font-weight: 500;
    }

    .Profile1--Paragraph {
        font-size: 14px;
        font-weight: 400;
    }
}