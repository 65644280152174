@import url(https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700&display=swap);
.Container {
   position: relative;
   text-align: center;
   color: white;
   background-color: black;
}
.Image {
    width: 100%;
    height: 80vh;
    object-fit: cover;
    object-position: center;
    opacity: 60%;
}
.TextContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.Title {
    color: white;
    font-style: italic;
    font-weight: 300;
    font-size: 50px;
    text-align: center;
    margin-bottom: 10px;
}
.SubTitle {
    font-style: italic;
    font-weight: 300;
    font-size: 30px;
    line-height: 40px;
    text-align: center;
    color: white;
}


@media only screen and (max-width: 600px) {
    .Title {
        font-size: 30px;
    }
    .SubTitle {
        font-size: 20px;
        line-height: 30px;
    }
}
.Banner2--Container {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
}

.Banner2-ImageDiv {
    width: 50%;
    height: 80vh;
    display: inline-block;
}

.Banner2--Image {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.Banner2--TextContainer {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: inline-block;
    padding: 40px;
}

.Banner2--Title {
    font-style: normal;
    font-weight: 600;
    font-size: 50px;
    margin-bottom: 20px;
}

.Banner2--SubTitle {
    font-style: normal;
    font-weight: 300;
    font-size: 30px;
    margin-bottom: 20px;
}

.Banner2--Contact {
    background: #000000;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    font-style: italic;
    font-weight: 600;
    font-size: 18px;
    border: none;
    color: white;
    padding: 8px 25px 8px 25px;
}
.Banner2--Contact:hover {
    opacity: 70%;
    cursor: pointer;
}

@media only screen and (max-width: 600px) {
    .Banner2-ImageDiv {
        width: 100%;
        height: 50vh;
    }

    .Banner2--TextContainer {
        width: 100%;
        padding: 20px;
        min-height: 40vh;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .Banner2--Title {
        font-size: 30px;
        margin-bottom: 10px;
        width: 100%;
    }

    .Banner2--SubTitle {
        font-size: 18px;
        margin-bottom: 10px;
        width: 100%;
    }

    .Banner2--Contact {
        font-size: 15px;
        padding: 8px 25px 8px 25px;
        margin-right: auto;
    }
}
.Banner3--Container {
    width: 100%;
    height: 90vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.Banner3--Left {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
}

.Banner3--Right {
    width: 50%;
    height: 100%;
    object-fit: cover;
    display: inline-block;
}

.Banner3--Image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.Banner3--TextContainer {
    width: 100%;
    padding: 20px;
}

.Banner3--Title {
    font-style: italic;
    font-weight: 700;
    font-size: 50px;
    margin-bottom: 10px;
}

.Banner3--Description {
    font-style: italic;
    font-size: 30px;
    line-height: 40px;
}

@media only screen and (max-width: 600px) {
    .Banner3--Container {
        width: 100%;
        min-height: 90vh;
    }

    .Banner3--Left {
        width: 100%;
        min-height: 50vh;
    }

    .Banner3--Right {
        width: 100%;
        min-height: 50vh;
    }

    .Banner3--Image {
        width: 100%;
    }

    .Banner3--TextContainer {
        width: 100%;
        padding: 20px;
    }

    .Banner3--Title {
        font-style: italic;
        font-weight: 700;
        font-size: 30px;
        margin-bottom: 10px;
    }

    .Banner3--Description {
        font-style: italic;
        font-size: 20px;
        line-height: 28px;
    }
}
.FAQ1--FAQ__Box {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    padding: 15px;
    cursor: pointer;
}

.FAQ1--Wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}

.FAQ1--Container {
    width: 80vw;
    max-width: 1000px;
}

.FAQ1--FAQ__Question {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
}

.FAQ1--FAQ__Answer {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 30px;
    padding: 20px;
}

.FAQ1--Row {
    margin-top: 20px;
    margin-bottom: 20px;
    font-family: "Open Sans";
    padding-left: 20px;
    padding-right: 20px;
}

@media only screen and (max-width: 600px) {
    .FAQ1--Row {
        padding-left: 10px;
        padding-right: 10px;
    }
    .FAQ1--FAQ__Answer {
        padding: 15px;
    }
    .FAQ1--Container {
        width: 95vw;
    }
}
.FAQ2--FAQ__Box {
    padding: 15px;
    cursor: pointer;
}

.FAQ2--Wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}

.FAQ2--Title {
    font-weight: 700;
}

.FAQ2--Container {
    width: 80vw;
    max-width: 1000px;
}

.FAQ2--FAQ__Question {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    font-style: italic;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.FAQ--Icon {
    max-width: 30px;
    max-height: 30px;
    color: black;
}

.FAQ2--FAQ__Answer {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 30px;
    padding-top: 20px;
    padding-bottom: 20px;
}

.FAQ2--Row {
    margin-top: 20px;
    margin-bottom: 20px;
    font-family: "Open Sans";
    padding-left: 20px;
    padding-right: 20px;
}

@media only screen and (max-width: 600px) {
    .FAQ2--Row {
        padding-left: 10px;
        padding-right: 10px;
    }
    .FAQ2--FAQ__Answer {
        padding: 15px;
    }
    .FAQ2--Container {
        width: 95vw;
    }
    .FAQ2--Title {
        font-weight: 700;
        font-size: 20px;
    }
}
.Profile1--Container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
}

.Profile1--TextContainer {
    display: flex;
    flex-direction: column;
    justify-content: start;
    width: 50%;
    align-items: flex-start;
    min-width: 50%;
}

.Profile1--Title {
    font-size: 24px;
    font-weight: 700;
}

.Profile1--Subtitle {
    font-size: 20px;
    font-weight: 500;
}

.Profile1--Paragraph {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
}

.Profile1--ImageContainer {
    width: 50%;
    height: 100%;
    object-fit: contain;
}

.Profile1--Image {
    object-fit: contain;
    max-height: 500px;
    width: 100%;
}

.Profile1--Button {
    color: black;
    padding: 10px 20px 10px 20px;
    border-radius: 10px;
    border: 2px solid black;
    font-weight: 600;
    margin-bottom: 10px;
}

.Profile1--Button:hover {
    animation: 0.3s linear;
    animation-name: buttonColourChange;
    animation-fill-mode: forwards;
    cursor: pointer;
}

@keyframes buttonColourChange {
    0% {
        background-color: white;
        color: black;
    }
    100% {
        background-color: black;
        color: white;
    }
}

@media only screen and (max-width: 600px) {
    .Profile1--TextContainer {
        width: 100%;
    }

    .Profile1--ImageContainer {
        width: 100%;
    }

    .Profile1--Title {
        font-size: 22px;
        font-weight: 700;
    }

    .Profile1--Subtitle {
        font-size: 18px;
        font-weight: 500;
    }

    .Profile1--Paragraph {
        font-size: 14px;
        font-weight: 400;
    }
}
.container {
    width: 100%;
    max-width: 1000px;
    display: flex;
    padding: 20px;
}

.d-flex {
    display: flex;
}

.flex-row {
    flex-direction: row;
}

.flex-wrap {
    flex-wrap: wrap;
}

.justify-content-center {
    justify-content: center;
}

.justify-space-around {
    justify-content: space-around;
}

.justify-space-between {
    justify-content: space-between;
}

.align-items-center {
    align-items: center;
}

.align-items-start {
    align-items: flex-start;
}

.align-items-end {
    align-items: end;
}

.p-5 {
    padding: 50px;
}
.p-4 {
    padding: 40px;
}
.p-3 {
    padding: 30px;
}
.p-2 {
    padding: 20px;
}
.p-1 {
    padding: 10px;
}
.w-100 {
    width: 100%;
}


/* BUTTONS */
.Button__Outlined {
    color: black;
    padding: 10px 20px 10px 20px;
    border-radius: 10px;
    border: 2px solid black;
    font-weight: 600;
}

.Button__Outlined:hover {
    animation: 0.3s linear;
    animation-name: buttonColourChange;
    animation-fill-mode: forwards;
    cursor: pointer;
}

@keyframes buttonColourChange {
    0% {
        background-color: white;
        color: black;
    }
    100% {
        background-color: black;
        color: white;
    }
}

@media only screen and (max-width: 600px) {
    .container {
        padding: 10px;
    }
}
.Spacer1--Large {
    padding: 20px;
}

@media only screen and (max-width: 600px) {
    .Spacer1--Large {
        padding: 10px;
    }
}
.Navbar1--Container {

}

.Navbar1--Upper--Title {
    font-weight: 600;
    font-size: 24px;
    margin-bottom: 0px;
    margin-left: 5px;
}

.Navbar1--Upper {
    padding: 10px;
    display: flex;
    justify-content: space-between;
}

.Navbar1--Lower {
    background-color: #e9e9e9;
    padding: 10px;
}

.Navbar1--SocialMediaDiv {
    margin: 3px;
}
.Card1--Image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
    max-height: 300px;
}

.Card1--Container {
    width: 50%;
    height: 100%;
    min-height: 200px;
    padding-right: 20px;
}

.Card1--Title {
    font-weight: 600;
    font-size: 22px;
}

.Card1--Description {
    font-weight: 400;
    font-size: 14px;
    margin-bottom: 40px;
    line-height: 24px;
}


@media only screen and (max-width: 600px) {
    .Card1--Container {
        width: 100%;
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .Card1--Description {
        margin-bottom: 20px;
    }
}

.SignUp-Image-Col {
    min-height: 100vh;
}

.SignUp-Image {
    background-image: url(/static/media/zen-yoga.fa77c157.jpg);
    background-size: cover;
    background-position: center;
    height: 100%;
    width: 100%;
}

.SignUp-Logo {
    height: 120px;
}

.SignUp-Info-Col {
    text-align: center;
}

.SignUp-Col {
    padding-top: 30px;
}

.SignUp-Col-Spacing {
    margin-bottom: 10px;
}

.SignUp-Button-Col {
    margin-top: 10px;
    margin-bottom: 10px;
}

.SignUp-FreeTrial-Text {
    margin-top: 20px;
    font-weight: 600;
}

.SignUp--Wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.SignUp--Container {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 90vw;
    max-width: 1400px;
    flex-wrap: wrap;
}

.SignUp--Left {
    width: 40%;
    min-height: 90vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.SignUp--Right {
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.SignUp--Image {
    max-width: 300px;
    height: 100%;
    object-fit: contain;
}

.SignUp--Phros {
    font-style: normal;
    font-weight: 300;
    font-size: 70px;
    line-height: 75px;
    margin-top: 10px;
}

.SignUp--Catch {
    font-size: 18px;
    line-height: 25px;
    font-weight: 300;
}
.SignUp--Catch--Span {
    font-weight: 600;
}

.SignUp--Input {
    margin-top: 10px;
    margin-bottom: 10px;
    border-radius: 0px !important;
    border-bottom: 1px solid black !important;
    padding-left: 0px !important;
}

.SignUp--Action {
    background: #000000;
    border-radius: 5px;
    border: none;
    color: white;
    width: 100%;
    height: 35px;
    margin-top: 20px;
}
.SignUp--Action {
    cursor: pointer;
}

@media only screen and (max-width: 600px) {
    .SignUp--Left {
        width: 100%;
        min-height: 30vh;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .SignUp--Right {
        width: 100%;
    }

    .SignUp--Image {
        max-height: 100px;
    }

    .SignUp--Phros {
        font-size: 50px;
        line-height: 65px;
    }

    .SignUp--Catch {
        text-align: center;
    }
}
.Logo-Image {
    height: 120px;
    max-height: 100%;
}

.Logo-Text {
    letter-spacing: 5px;
    font-size: 50px;
    font-weight: 200;
}

@media only screen and (max-width: 576px) {
    .Logo-Text {
        letter-spacing: 5px;
        font-size: 40px;
        font-weight: 200;
    }
    .Logo-Image {
        height: 80px;
    }

}
.H1 {
    font-size: 30px;
    font-weight: 300;
    letter-spacing: 2px;
}

.H1-Highlight {
    background: linear-gradient(to top, #F7B733 30%, transparent 30%);
}

.H2 {
    font-size: 25px;
    font-weight: 300;
    letter-spacing: 2px;
}

.H2-Highlight {
    background: linear-gradient(to top, #F7B733 20%, transparent 20%);
}

.H3 {
    font-size: 18px;
    font-weight: 300;
    letter-spacing: 2px;
}

.H3-Highlight {
    background: linear-gradient(to top, #F7B733 20%, transparent 20%);
}
.CardButton-Div {
    padding-top: 70px;
    padding-bottom: 70px;
    background-color: white;
    width: 100%;
    text-align: center;
    border-radius: 10px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

@media only screen and (max-width: 576px) {
    .CardButton-Div {
        padding-top: 40px;
        padding-bottom: 40px;
    }
}
.Upload--Button{height:60px;width:60px;border-radius:5px;border:1px solid #000;box-sizing:border-box}.Upload--File{border-radius:3px;border-bottom:1px solid #000;box-sizing:border-box;padding-top:10px;padding-bottom:10px;margin-top:20px}.Upload--AddDetails{margin-right:8px}.Upload--AddDetails--TextArea{white-space:pre-wrap}

.SideNav-Logo {
    max-height: 100%;
    max-width: 100%;
    height: 100px;
    min-width: 100px;
}

.SideNav-Row {
    padding: 10px;
}

.SideNav-Col {
    text-align: center;
}

.SideNav-Main-Col {
    text-align: center;
}

.SideNav-Sider {
    min-height: 100vh;
}

.SideNav-Text {
    color: black;
}

.SideNav-Divider {
    background-color: rgb(225, 225, 225);
}

.SideNav-Icon-Style {
    font-size: 25px;
    margin-right: 5px;
}

.SideNav-Menu-Text {
    font-size: 16px;
    padding: 3px;
    margin-left: 3px;
    letter-spacing: 2px;
    color: black;
}
.Location-SearchBar-Primary {
    margin-top: 1vh;
    margin-bottom: 1vh;
    overflow-x: scroll;
}
.Dashboard-Row {
    margin-top: 20px;
}
.Profile-Row {
    margin-top: 30px;
}

.Profile-ProfilePicture {
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: 200px;
    object-fit: cover;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
}

.Profile-LogoPicture {
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: 200px;
    object-fit: contain;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
}

.Profile-Select {
    width: 100%;
}

.Profile-SocialMedia-Items {
    margin-top: 5px;
    margin-bottom: 5px;
}

.Profile-Colour-Div {
    height: 50px;
    width: 50px;
}

.Profile-Colour-Outer-Div {
    margin-bottom: 20px;
    display: block;
}
.stripe-connect {
    background: #0A1612;
    display: inline-block;
    height: 40px;
    text-decoration: none;
    width: 210px;

    border-radius: 4px;
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;

    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;

    -webkit-font-smoothing: antialiased;
  }

  .stripe-connect span {
    color: #ffffff;
    display: block;
    font-family: sohne-var, "Helvetica Neue", Arial, sans-serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 14px;
    padding: 11px 0px 0px 24px;
    position: relative;
    text-align: left;
  }

  .stripe-connect:hover {
    background: #7a73ff;
  }

  .stripe-connect.slate {
    background: #0A1612;
  }

  .stripe-connect.slate:hover {
    background: #425466;
  }

  .stripe-connect.white {
    background: #ffffff;
  }

  .stripe-connect.white span {
    color: #0a2540;
  }

  .stripe-connect.white:hover {
    background: #f6f9fc;
  }

  .stripe-connect span::after {
    background-repeat: no-repeat;
    background-size: 49.58px;
    content: "";
    height: 20px;
    left: 62%;
    position: absolute;
    top: 28.95%;
    width: 49.58px;
  }

  /* Logos */
  .stripe-connect span::after {
    background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 23.0.4, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 468 222.5' style='enable-background:new 0 0 468 222.5;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill-rule:evenodd;clip-rule:evenodd;fill:%23FFFFFF;%7D%0A%3C/style%3E%3Cg%3E%3Cpath class='st0' d='M414,113.4c0-25.6-12.4-45.8-36.1-45.8c-23.8,0-38.2,20.2-38.2,45.6c0,30.1,17,45.3,41.4,45.3 c11.9,0,20.9-2.7,27.7-6.5v-20c-6.8,3.4-14.6,5.5-24.5,5.5c-9.7,0-18.3-3.4-19.4-15.2h48.9C413.8,121,414,115.8,414,113.4z M364.6,103.9c0-11.3,6.9-16,13.2-16c6.1,0,12.6,4.7,12.6,16H364.6z'/%3E%3Cpath class='st0' d='M301.1,67.6c-9.8,0-16.1,4.6-19.6,7.8l-1.3-6.2h-22v116.6l25-5.3l0.1-28.3c3.6,2.6,8.9,6.3,17.7,6.3 c17.9,0,34.2-14.4,34.2-46.1C335.1,83.4,318.6,67.6,301.1,67.6z M295.1,136.5c-5.9,0-9.4-2.1-11.8-4.7l-0.1-37.1 c2.6-2.9,6.2-4.9,11.9-4.9c9.1,0,15.4,10.2,15.4,23.3C310.5,126.5,304.3,136.5,295.1,136.5z'/%3E%3Cpolygon class='st0' points='223.8,61.7 248.9,56.3 248.9,36 223.8,41.3 '/%3E%3Crect x='223.8' y='69.3' class='st0' width='25.1' height='87.5'/%3E%3Cpath class='st0' d='M196.9,76.7l-1.6-7.4h-21.6v87.5h25V97.5c5.9-7.7,15.9-6.3,19-5.2v-23C214.5,68.1,202.8,65.9,196.9,76.7z'/%3E%3Cpath class='st0' d='M146.9,47.6l-24.4,5.2l-0.1,80.1c0,14.8,11.1,25.7,25.9,25.7c8.2,0,14.2-1.5,17.5-3.3V135 c-3.2,1.3-19,5.9-19-8.9V90.6h19V69.3h-19L146.9,47.6z'/%3E%3Cpath class='st0' d='M79.3,94.7c0-3.9,3.2-5.4,8.5-5.4c7.6,0,17.2,2.3,24.8,6.4V72.2c-8.3-3.3-16.5-4.6-24.8-4.6 C67.5,67.6,54,78.2,54,95.9c0,27.6,38,23.2,38,35.1c0,4.6-4,6.1-9.6,6.1c-8.3,0-18.9-3.4-27.3-8v23.8c9.3,4,18.7,5.7,27.3,5.7 c20.8,0,35.1-10.3,35.1-28.2C117.4,100.6,79.3,105.9,79.3,94.7z'/%3E%3C/g%3E%3C/svg%3E");
  }

  .stripe-connect.white span::after {
    background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 24.0.3, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 468 222.5' style='enable-background:new 0 0 468 222.5;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill-rule:evenodd;clip-rule:evenodd;fill:%230A2540;%7D%0A%3C/style%3E%3Cg%3E%3Cpath class='st0' d='M414,113.4c0-25.6-12.4-45.8-36.1-45.8c-23.8,0-38.2,20.2-38.2,45.6c0,30.1,17,45.3,41.4,45.3 c11.9,0,20.9-2.7,27.7-6.5v-20c-6.8,3.4-14.6,5.5-24.5,5.5c-9.7,0-18.3-3.4-19.4-15.2h48.9C413.8,121,414,115.8,414,113.4z M364.6,103.9c0-11.3,6.9-16,13.2-16c6.1,0,12.6,4.7,12.6,16H364.6z'/%3E%3Cpath class='st0' d='M301.1,67.6c-9.8,0-16.1,4.6-19.6,7.8l-1.3-6.2h-22v116.6l25-5.3l0.1-28.3c3.6,2.6,8.9,6.3,17.7,6.3 c17.9,0,34.2-14.4,34.2-46.1C335.1,83.4,318.6,67.6,301.1,67.6z M295.1,136.5c-5.9,0-9.4-2.1-11.8-4.7l-0.1-37.1 c2.6-2.9,6.2-4.9,11.9-4.9c9.1,0,15.4,10.2,15.4,23.3C310.5,126.5,304.3,136.5,295.1,136.5z'/%3E%3Cpolygon class='st0' points='223.8,61.7 248.9,56.3 248.9,36 223.8,41.3 '/%3E%3Crect x='223.8' y='69.3' class='st0' width='25.1' height='87.5'/%3E%3Cpath class='st0' d='M196.9,76.7l-1.6-7.4h-21.6v87.5h25V97.5c5.9-7.7,15.9-6.3,19-5.2v-23C214.5,68.1,202.8,65.9,196.9,76.7z'/%3E%3Cpath class='st0' d='M146.9,47.6l-24.4,5.2l-0.1,80.1c0,14.8,11.1,25.7,25.9,25.7c8.2,0,14.2-1.5,17.5-3.3V135 c-3.2,1.3-19,5.9-19-8.9V90.6h19V69.3h-19L146.9,47.6z'/%3E%3Cpath class='st0' d='M79.3,94.7c0-3.9,3.2-5.4,8.5-5.4c7.6,0,17.2,2.3,24.8,6.4V72.2c-8.3-3.3-16.5-4.6-24.8-4.6 C67.5,67.6,54,78.2,54,95.9c0,27.6,38,23.2,38,35.1c0,4.6-4,6.1-9.6,6.1c-8.3,0-18.9-3.4-27.3-8v23.8c9.3,4,18.7,5.7,27.3,5.7 c20.8,0,35.1-10.3,35.1-28.2C117.4,100.6,79.3,105.9,79.3,94.7z'/%3E%3C/g%3E%3C/svg%3E");
  }

.AddLiveClass-Row {
    margin-top: 20px;
}

.AddLiveClass-Input {
    width: 100%;
}

.AddLiveClass-Availability-Row {
    padding-top: 30px;
    padding-bottom: 30px;
}

.AddLiveClass-Save-Buttons {
    margin-top: 20px;
    margin-bottom: 20px;
}

.AddLiveClass-Availability-Delete {
    height: 100%;
    width: 100%;
}
.ViewLiveClasses-Row {
    margin-top: 20px;
}

.ViewLiveClasses-Name-Row {
    margin-top: 20px;
    margin-bottom: 10px;
}

.ViewLiveClasses-Sub-Row {
    margin-top: 10px;
    margin-bottom: 10px;
}
.ReviewWebsite-Row {
    margin-top: 20px;
}

.Pricing-Logo {
    max-height: 70px;
    max-width: 70px;
}

.Pricing-Phros-Name {
    font-size: 50px;
    font-weight: 200;
    letter-spacing: 2px;
}

.PricingCard-Div {
    background: #FFFFFF;
    box-sizing: border-box;
    box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: 30px;
    height: 100%;
    width: 100%;
}

.PricingCard-PlanName {
    font-style: normal;
    font-weight: bold;
    font-size: 23px;
    line-height: 29px;
    color: #000000;
    text-align: center;
}

.PricingCard-PlanDescription {
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 21px;
    text-align: center;
}

.Pricing-PlanDescription {
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 21px;
}


.PricingCard-Pricing {
    font-style: normal;
    font-weight: 400;
    font-size: 34px;
    line-height: 47px;
    text-align: center;
}

.PricingCard-PriceDuration {
    font-style: normal;
    font-weight: 200;
    font-size: 23px;
    line-height: 29px;
}

.PricingCard-Price {
    display: inline;
}

.PriceCard-YellowLine {
    width: 100%;
    height: 10px;
    left: 142px;
    top: 423px;
    background: #F7B733;
}

.PricingCard-Button {
    background: #0A1612;
    border: 0.2px solid #000000;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    color: white;
    width: 100%;
    height: 35px;
    font-weight: 400;
    font-size: 18px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.PricingCard-Button:hover {
    background: #232424;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.85);
    cursor: pointer;
}

.PricingCard-Features {
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 29px;
}

.PricingCard-FeatureList {
    font-style: normal;
    font-weight: 200;
    font-size: 16px;
    line-height: 21px;
}

.Pricing-Main-Div {
    padding-bottom: 20px;
}
.AddWorkout-Week {
    background-color: white;
    border-radius: 10px;
    padding: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.AddWorkout-Day {
    background-color: white;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border: 1px solid rgba(0, 0, 0, 0.2);
}

.AddWorkout-Video {
    width: 100%;
    height: 200px;
}

.ql-editor strong{
    font-weight:bold;
}
.Program-Week-Day {
    width: 100%;
    min-height: 100px;
    background: #FFFFFF;
    border: 0.2px solid #000000;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.Program-Week-Day-Add {
    height: 100px;
}

.Program-Week-Day-Delete {
    margin-left: 5px;
    width: 100%;
    min-height: 100px;
}

.Program-Workout-Planner-Large-Action {
    width: 100%;
    min-height: 200px;
    font-size: 24px;
    font-weight: bold;
    border: 0.2px solid #000000;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
}


.Workout-Tag {
    margin-top: 4px;
    margin-bottom: 4px;
    padding: 3px;
    min-width: 30px;
    min-height: 20px;
}
.Workout-Tag:hover {
    cursor: pointer
}

.Program-Workout-Exercise {
}

.Program-Create-Superset {
    background-color: black;
    color: white;
    border-radius: 10px;
}

.Program-Exercise-Row {
    margin-top: 10px;
    margin-bottom: 10px;
}

.Program-Workout-Save-Workout {
    border-radius: 10px;
    background-color: black;
    color: white;
}

.Program-Workout-Delete-Workout {
    border-radius: 10px;
}

.Program-Workout-Name {
    font-size: 15px;
    font-weight: bold;
    margin-left: 10px;
}

.Program-Input-Width {
    width: 100%;
}
.Aumthenticus-VideoBanner {
    width: 100%;
    min-height: 500px;
    object-fit: cover;
    object-position: center;
}

.Aumthenticus-Page-Gallery-Image {
    width: 100%;
    height: 300px;
    object-position: center;
    object-fit: cover;
}

.Aumthenticus-Image {
    width: 400px;
    height: 300px;
    object-position: center;
    object-fit: cover;
}
.Coupon-Row {
    margin-top: 20px;
    margin-bottom: 20px;
}


.Gallery-Card-Style {
    width: 400px;
    height: 250px;
    object-fit: cover;
    object-position: center;
}

.Gallery-Card-Image-Style {
    width: 100%;
    height: 250px;
    object-fit: cover;
    object-position: center;
}
.Banner1-Main-Col {
    background-color: white;
    font-family: "Open Sans";
}

.Banner1-Img {
    width: 100%;
    height: 90vh;
    object-fit: cover;
    object-position: center;
    opacity: 0.6;
}

.Banner1-Main-Div {
    background-color: black;
    position: relative;
}

.Banner1-Text-Div {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width: 500px;
}

.Banner1-FirstName {
    text-align: left;
    color: white;
    font-style: italic;
    font-weight: 800;
    font-size: 70px;
    margin-bottom: 10px;
    line-height: 65px;
    word-wrap: break-word;
}

.Banner1-LastName {
    text-align: left;
    color: white;
    font-style: italic;
    font-weight: 800;
    font-size: 70px;
    margin-bottom: 10px;
    line-height: 65px;
    word-wrap: break-word;
}

.Banner1-BusinessStatement {
    text-align: left;
    color: white;
    font-style: italic;
    font-weight: 600;
    font-size: 25px;
    line-height: 35px;
    max-width: 700px;
    margin-top: 10px;
    word-wrap: break-word;
}

.Banner1-Input {
    border-radius: 10px;
    padding: 7px;
}

.Banner1-Contact-Button {
    background: #000000;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    border: black;
    color: white;
    height: 100%;
    width: 100%;
    padding: 7px;
}

.Banner1-Contact-Section {
    width: 100%;
}

@media only screen and (max-width: 600px) {
    .Banner1-Text-Div {
        min-width: 0px;
    }
}


.Banner2-Main-Col {
    background-color: white;
    font-family: "Open Sans";
}

.Banner2-Img {
    width: 100%;
    height: 90vh;
    object-fit: cover;
    object-position: center;
    opacity: 0.6;
}

.Banner2-Main-Div {
    background-color: black;
    position: relative;
    text-align: center;
}

.Banner2-Text-Div {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
}

.Banner2-Name {
    text-align: left;
    color: white;
    font-style: italic;
    font-weight: 300;
    font-size: 70px;
    line-height: 75px;
    margin-bottom: 10px;
    text-align: center;
    word-wrap: break-word;
}

.Banner2-BusinessStatement {
    text-align: left;
    color: white;
    font-style: italic;
    font-weight: 300;
    font-size: 30px;
    line-height: 45px;
    text-align: center;
    min-width: 700px;
    word-wrap: break-word;
}

@media only screen and (max-width: 600px) {
    .Banner2-BusinessStatement {
        min-width: 0px;
    }
}
.Banner3-Main-Col {
    background-color: white;
    font-family: "Open Sans";
    padding: 20px;
}

.Banner3-Img {
    width: 100%;
    height: 90vh;
    object-fit: cover;
    object-position: center;
    border-radius: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.Banner2-Main-Div {
    background-color: black;
    position: relative;
    text-align: center;
}

.Banner2-Text-Div {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
}

.Banner3-Name {
    text-align: left;
    color: black;
    font-style: italic;
    font-weight: bold;
    font-size: 60px;
    line-height: 70px;
    margin-bottom: 10px;
    word-wrap: break-word;
}

.Banner3-BusinessStatement {
    text-align: left;
    color: black;
    font-style: normal;
    font-weight: 300;
    font-size: 25px;
    line-height: 30px;
    margin-top: 25px;
    margin-bottom: 25px;
    word-wrap: break-word;
}

.Banner3-Input {
    border-radius: 10px;
    padding: 7px;
}

.Banner3-Contact-Button {
    background: #000000;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    border: black;
    color: white;
    height: 100%;
    width: 100%;
    padding: 7px;
}
.Banner4-Main-Col {
    background-color: white;
    font-family: "Open Sans";
    padding: 20px;
}

.Banner4-Img {
    width: 100%;
    height: 90vh;
    object-fit: cover;
    object-position: center;
    border-radius: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.Banner4-Name {
    text-align: left;
    color: black;
    font-style: italic;
    font-weight: bold;
    font-size: 60px;
    line-height: 70px;
    margin-bottom: 10px;
    word-wrap: break-word;
}

.Banner4-BusinessStatement {
    text-align: left;
    color: black;
    font-style: normal;
    font-weight: 300;
    font-size: 25px;
    line-height: 30px;
    margin-top: 25px;
    margin-bottom: 25px;
    word-wrap: break-word;
}

.Banner4-Input {
    border-radius: 10px;
    padding: 7px;
}

.Banner4-Contact-Button {
    background: #000000;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    border: black;
    color: white;
    height: 100%;
    width: 100%;
    padding: 7px;
}

.Banner4-Text-Col {
    padding-left: 30px;
}

@media only screen and (max-width: 600px) {
    .Banner4-Text-Col {
        padding-left: 0px;
    }
    .Banner4-Name {
        font-size: 50px;
    }
    .Banner4-BusinessStatement {
        font-size: 25px;
    }
}
.Navbar1-Main-Row {
    background-color: white;
    padding: 10px;
    font-family: "Open Sans";
}

.Navbar1-Large-Logo {
    max-width: 100px;
    max-height: 60px;
    object-fit: contain;
    margin-right: 10px;
}

.Navbar1-Large-BusinessName {
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 23px;
}

.Navbar1-Large-Link-Text {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 23px;
    color: black;
    margin-left: 3px;
    margin-right: 3px;
    padding-left: 0px;
    padding-right: 0px;
}

.Navbar1-React-Social-Icon {
    max-height: 25px;
    max-width: 25px;
}

.Navbar1-Large-Login-Button {
    border-radius: 10px;
    background-color: black;
    color: white;
    border-color: black;
    padding: 5px 15px 5px 15px;
}

.Navbar1-Large-Login-Button:hover {
    opacity: 80%;
    cursor: pointer;
}

.Navbar1-Shopping-Cart {
    font-size: 25px;
    color: black;
}

.Navbar-Small-Logo-Name {
    text-align: center;
}

.Navbar1-Small-Logo {
    max-height: 50px;
    max-width: 100%;
}

.Navbar1-Small-BusinessName {
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 23px;
}

.Navbar-Drawer {
    min-width: 300px;
}

.Navbar-Small-Drawer-Link {
    text-align: center;
    margin-top: 5px;
    margin-bottom: 5px;
}

@media only screen and (max-width: 600px) {
    .Navbar1-Large-BusinessName {
        font-size: 13px;
    }

    .Navbar1-Large-Link-Text {
        text-align: center;
        font-size: 15px;
        font-weight: 300;
    }
}
.Navbar2-Main-Row {
    background-color: black;
    padding: 10px;
    font-family: "Open Sans";
}

.Navbar2-Large-Logo {
    max-width: 100px;
    max-height: 60px;
    object-fit: contain;
    border-radius: 10px;
    margin-right: 10px;
}

.Navbar2-Large-BusinessName {
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 23px;
    color: white;
}

.Navbar2-Large-Link-Text {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 23px;
    color: white;
    margin-left: 3px;
    margin-right: 3px;
    padding-left: 0px;
    padding-right: 0px;
}

.Navbar2-React-Social-Icon {
    max-height: 25px;
    max-width: 25px;
}

.Navbar2-Large-Login-Button {
    border-radius: 10px;
    background-color: white;
    color: black;
    border-color: white;
    padding: 5px 15px 5px 15px;
    border: none;
}

.Navbar2-Large-Login-Button:hover {
    opacity: 80%;
    cursor: pointer;
}

.Navbar2-Shopping-Cart {
    font-size: 25px;
    color: white
}

.Navbar-Small-Logo-Name {
    text-align: center;
}

.Navbar2-Small-Logo {
    max-height: 50px;
    max-width: 100%;
}

.Navbar2-Small-BusinessName {
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 23px;
}

.Navbar-Drawer {
    min-width: 300px;
}

.Navbar-Small-Drawer-Link {
    text-align: center;
    margin-top: 5px;
    margin-bottom: 5px;
}

@media only screen and (max-width: 600px) {
    .Navbar2-Large-BusinessName {
        font-size: 13px;
    }

    .Navbar2-Large-Link-Text {
        color: black;
    }

    .Navbar2-Shopping-Cart {
        color: black;
    }

    .Navbar2-Large-Login-Button {
        border-radius: 10px;
        background-color: black;
        color: white;
        border: none;
        padding: 5px 15px 5px 15px;
    }

    .Navbar2-Large-Link-Text {
        text-align: center;
        font-size: 15px;
        font-weight: 300;
    }
}
.Navbar3-Main-Col {
    background-color: white;
    padding: 10px;
    font-family: "Open Sans";
}

.Navbar3-Second-Bar {
    background-color: #E9E9E9;
    padding-top: 10px;
    padding-bottom: 10px;
}
.AboutMe-Row {
    font-family: "Open Sans";
    background-color: white;
}

.AboutMe1-Image {
    height: 400px;
    width: 400px;
    object-fit: cover;
    object-position: center;
    border-radius: 50%;
    text-align: center;
}

.AboutMe1-Image-Col {
    text-align: center;
}

.AboutMe1-Name {
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 40px;
    margin-bottom: 0px;
}

.AboutMe1-Title {
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 41px;
}

.AboutMe-Contact-Me {
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 8px;
    padding-bottom: 8px;
    background-color: black;
    color: white;
    border-radius: 10px;
}

.AboutMe-Contact-Me:hover {
    color: white;
    opacity: 80%;
}

@media only screen and (max-width: 600px) {
    .AboutMe1-Image {
        height: 200px;
        width: 200px;
    }
}
.AboutMe-Row {
    font-family: "Open Sans";
    background-color: white;
}

.AboutMe2-Image {
    height: 50vh;
    width: 100%;
    object-fit: cover;
    object-position: center;
    text-align: center;
    border-radius: 5px;
}

.AboutMe2-Name {
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 40px;
    margin-bottom: 0px;
}

.AboutMe2-Title {
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 41px;
}

@media only screen and (max-width: 600px) {
    .AboutMe2-Image {
        height: 60vh;
    }
}
.AboutMe3-Image {
    height: 70vh;
    width: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: 10px;
}
.Gallery1-Image {
    width: 100%;
    height: 200px;
    object-fit: cover;
    object-position: center;
    border-radius: 5px;
}

.Service1-Image {
    width: 100%;
    height: 100%;
    max-height: 250px;
    object-position: center;
    object-fit: cover;
    border-radius: 5px;
}

.Service1-Title {
    font-style: normal;
    font-weight: 600;
    font-size: 23px;
    line-height: 30px;
    color: #000000;
}

.Service1-Paragraph {
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 24px;
    color: #000000;
}

.Service1-Div {
    padding: 20px;
    font-family: "Open Sans";
}

.Service1-Image-Div {
    padding: 10px;
}

.Service-Button {
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 8px;
    padding-bottom: 8px;
    background-color: black;
    color: white;
    border-radius: 10px;
}

.Service-Button:hover {
    color: white;
    opacity: 60%;
}
.Service2-Image {
    width: 100%;
    height: 200px;
    object-fit: cover;
    object-position: center;
}

.Service2-Image {
    border-radius: 5px 5px 0px 0px;
}

.Service2-Div {
    padding: 20px;
    font-family: "Open Sans";
}

.Service2-Card {
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
}

.Service2-Title {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #000000;
}

.Service2-Description {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    color: #000000;
}
.Service3-Image {
    background-color: black;
    opacity: 70%;
    object-fit: cover;
    height: 100%;
    width: 100%;
}

.Service3-Card {
    background-color: black;
    position: relative;
    color: white;
}

.Service3-Text-Div {
    color: white;
    padding: 35px;
    font-family: "Open Sans";
}

.Service3-Title {
    color: white;
    opacity: 100%;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
}

.Service3-Description {
    color: white;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 24px;
}

.Service-Button-Inverted {
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 8px;
    padding-bottom: 8px;
    background-color: white;
    color: black;
    border: none;
    border-radius: 10px;
}

.Service-Button-Inverted:hover {
    color: black;
    opacity: 60%;
}
.FAQ1-Row {
    margin-top: 20px;
    margin-bottom: 20px;
    font-family: "Open Sans";
}

.FAQ1-Question {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
}

.FAQ1-Question-Container {
    background: #F8F8F8;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    padding: 15px;
    cursor: pointer;
}

.FAQ1-Answer {
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 30px;
    padding: 20px;
}
.FAQ2-Row {
    margin-top: 20px;
    margin-bottom: 20px;
    font-family: "Open Sans";
}

.FAQ2-Question {
    font-style: italic;
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
}

.FAQ2-Question-Container {
    padding: 15px;
    cursor: pointer;
}

.FAQ2-Answer {
    background: #FFFFFF;
    border-radius: 5px;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 30px;
    padding-left: 20px;
    padding-right: 20px;
}
.Testimonial1-Image {
    width: 100%;
    height: 100%;
    max-height: 250px;
    object-position: center;
    object-fit: cover;
    border-radius: 5px;
}

.Testimonial1-Title {
    font-style: normal;
    font-weight: 600;
    font-size: 23px;
    line-height: 30px;
    color: #000000;
}

.Testimonial1-Paragraph {
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 24px;
    color: #000000;
}

.Testimonial1-Div {
    padding: 20px;
    font-family: "Open Sans";
}

.Testimonial1-Image-Div {
    padding: 10px;
}
.Testimonial2-Image {
    width: 100%;
    height: 200px;
    object-fit: cover;
    object-position: center;
}

.Testimonial2-Image {
    border-radius: 5px 5px 0px 0px;
}

.Testimonial2-Div {
    padding: 20px;
    font-family: "Open Sans";
}

.Testimonial2-Card {
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
}

.Testimonial2-Title {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #000000;
}

.Testimonial2-Description {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    color: #000000;
}
.Testimonial3-Select-Card {
    width: 100%;
    padding: 15px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    cursor: pointer;
}

.Testimonial3-Title {
    font-family: "Open Sans";
    font-style: italic;
    font-weight: 600;
    font-size: 16px;
    line-height: 34px;
    margin-left: 10px;
}

.Testimonial3-Title-Displayed {
    font-family: "Open Sans";
    font-style: italic;
    font-weight: 600;
    font-size: 20px;
    line-height: 34px;
}


.Testimonial3-Image {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    object-fit: cover;
    object-position: center;
}

.Testimonial3-Pagination {
    text-align: center;
    color: black;
}

.Testimonial3-Text-Div {
    padding: 20px;
}

.Testimonial3-Description {
    font-family: "Open Sans";
    font-style: italic;
    font-weight: 400;
    font-size: 16px;
    line-height: 34px;
}
.Contact1-Input {
    border-radius: 5px;
    font-family: "Open Sans";
    font-style: italic;
    font-weight: 500;
}

.Contact1-Title {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 30px;
}

.Contact1-Contact-Me {
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 6px;
    padding-bottom: 6px;
    background-color: black;
    color: white;
    border-radius: 10px;
    border: none;
}

.Contact1-Contact-Me:hover {
    color: white;
    opacity: 80%;
    cursor: pointer;
}

.Section-Component-Title {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 30px;
}
.Contact2-Input {
    border-radius: 5px;
    font-family: "Open Sans";
    font-style: italic;
    font-weight: 500;
}

.Contact2-Title {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 30px;
}

.Contact2-Contact-Me {
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 6px;
    padding-bottom: 6px;
    background-color: black;
    color: white;
    border-radius: 10px;
    border: none;
}

.Contact2-Contact-Me:hover {
    color: white;
    opacity: 80%;
    cursor: pointer;
}

.Contact2-Divider {
    color: black;
    background-color: black;
}

.Contact2-HR {
    border-top: 1px solid black;
    background-color: black;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 20px;
}
.SectionOrder-Selection {
    width: 100%;
    text-align: center;
    padding: 30px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    font-size: 23px;
    font-weight: 600;
    font-family: "Open Sans";
    height: 100%;
}

.SectionOrder-Selection:hover {
    background: #F8F8F8;
    cursor:pointer;
}

.SectionOrder-Selection-Selected {
    width: 100%;
    text-align: center;
    padding: 30px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    font-size: 23px;
    font-weight: 600;
    font-family: "Open Sans";
    height: 100%;
    background: #FFD43D;
    cursor: pointer;
}
.PreviewWebsite-Div {
    padding-top: 70px;
}

.PreviewWebsite-Div-First {
    padding-top: 100px;
}

.PreviewWebsite-Div-Last {
    padding-top: 70px;
    padding-bottom: 100px;
}

.PreviewWebsite-Max-Col {
    max-width: 1000px;
}
.AddProgram{font-family:"Roboto";padding:20px;padding-left:40px;width:500px}.AddProgram--Title{font-weight:bold;font-size:18px;line-height:20px}.AddProgram--Subtitle{font-size:13px;line-height:18px;margin-bottom:40px}.AddProgram--Input{background:#fff;border:1px solid #000;box-sizing:border-box;border-radius:5px;white-space:pre-wrap}.AddProgram--Input__Small{background:#fff;border:1px solid #000;box-sizing:border-box;border-radius:5px}.AddProgram--Input--Div{margin-top:25px}.AddProgram--Input--Label{font-weight:bold;font-size:12px;line-height:14px;margin-bottom:3px;width:100%}.ViewPrograms{padding:20px;padding-left:40px}.Flex-Margin{margin-left:10px}.Select--FullWidth{width:100%}@media only screen and (max-width: 600px){.AddProgram{padding:10px;padding-left:10px;width:100%}.AddProgram--Subtitle{margin-bottom:25px}.AddProgram--Input__Small{max-width:100%}.ViewPrograms{padding:10px;padding-left:10px}}
.SiderText {
    color: rgba(255, 255, 255, 0.65);
}
.SiderText:hover {
    color: white;
}
.ModalElement {
    border: 1px solid black;
    border-radius: 5px;
    padding: 20px;
    margin-bottom: 10px;
    margin-top: 10px;
    position: relative;
}
.ModalElement:hover {
    cursor: pointer;
}
.ModelElement-Cover {
    background-color: transparent;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
}
.WebElement--Buttons {
    position: relative;
    display: none;
}
.WebElement--Div:hover .WebElement--Buttons {
    cursor: pointer;
    display: block;
    z-index: 1;
}
.WebElement--Div:hover {
    border: 1px solid black;
    border-radius: 5px;
}
.WebElement--Edit {
    position: absolute;
    top: 10px;
    right: 40px;
}
.WebElement--Delete {
    position: absolute;
    top: 10px;
    right: 5px;
}
body {
    font-family: 'Roboto', sans-serif;
}

h1 {
    font-size: 25px;
    font-weight: 300;
}

h2 {
    font-size: 20px;
    font-weight: 300;
}

h3 {
    font-size: 16px;
    font-weight: 400;
}

h4 {
    font-weight: 300;
}

p {
    font-weight: 300;
}

.Button {
    background-color:#0A1612;
    border-radius: 10px;
    border-color: #0A1612;
    color: white;
}

.Button:hover {
    background-color: #606161;
    border-color: #606161;
}

.Button:focus:active {
    background-color: #606161;
    border-color: #606161;
}

.Button-Yellow {
    background-color:#F7B733;
    border-radius: 10px;
    border-color: #F7B733;
    color: 'black';
}

.Button-Yellow:hover {
    background-color: #946e20;
    border-color: #946e20;
}

.Button-Yellow:focus:active {
    background-color: #946e20;
    border-color: #946e20;
}

.flex-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.flex-box {
    display: block;
}

.justify-start {
    justify-content: start;
}

.justify-around {
    justify-content: around;
}
