.FAQ2-Row {
    margin-top: 20px;
    margin-bottom: 20px;
    font-family: "Open Sans";
}

.FAQ2-Question {
    font-style: italic;
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
}

.FAQ2-Question-Container {
    padding: 15px;
    cursor: pointer;
}

.FAQ2-Answer {
    background: #FFFFFF;
    border-radius: 5px;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 30px;
    padding-left: 20px;
    padding-right: 20px;
}