.Contact1-Input {
    border-radius: 5px;
    font-family: "Open Sans";
    font-style: italic;
    font-weight: 500;
}

.Contact1-Title {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 30px;
}

.Contact1-Contact-Me {
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 6px;
    padding-bottom: 6px;
    background-color: black;
    color: white;
    border-radius: 10px;
    border: none;
}

.Contact1-Contact-Me:hover {
    color: white;
    opacity: 80%;
    cursor: pointer;
}

.Section-Component-Title {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 30px;
}