.SideNav-Logo {
    max-height: 100%;
    max-width: 100%;
    height: 100px;
    min-width: 100px;
}

.SideNav-Row {
    padding: 10px;
}

.SideNav-Col {
    text-align: center;
}

.SideNav-Main-Col {
    text-align: center;
}

.SideNav-Sider {
    min-height: 100vh;
}

.SideNav-Text {
    color: black;
}

.SideNav-Divider {
    background-color: rgb(225, 225, 225);
}

.SideNav-Icon-Style {
    font-size: 25px;
    margin-right: 5px;
}

.SideNav-Menu-Text {
    font-size: 16px;
    padding: 3px;
    margin-left: 3px;
    letter-spacing: 2px;
    color: black;
}