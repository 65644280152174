.AddWorkout-Week {
    background-color: white;
    border-radius: 10px;
    padding: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.AddWorkout-Day {
    background-color: white;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border: 1px solid rgba(0, 0, 0, 0.2);
}

.AddWorkout-Video {
    width: 100%;
    height: 200px;
}