.Container {
   position: relative;
   text-align: center;
   color: white;
   background-color: black;
}
.Image {
    width: 100%;
    height: 80vh;
    object-fit: cover;
    object-position: center;
    opacity: 60%;
}
.TextContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.Title {
    color: white;
    font-style: italic;
    font-weight: 300;
    font-size: 50px;
    text-align: center;
    margin-bottom: 10px;
}
.SubTitle {
    font-style: italic;
    font-weight: 300;
    font-size: 30px;
    line-height: 40px;
    text-align: center;
    color: white;
}


@media only screen and (max-width: 600px) {
    .Title {
        font-size: 30px;
    }
    .SubTitle {
        font-size: 20px;
        line-height: 30px;
    }
}