.AddProgram {
    font-family: "Roboto";
    padding: 20px;
    padding-left: 40px;
    width: 500px;

    &--Title {
        font-weight: bold;
        font-size: 18px;
        line-height: 20px;
    }

    &--Subtitle {
        font-size: 13px;
        line-height: 18px;
        margin-bottom: 40px;
    }

    &--Input {
        background: #FFFFFF;
        border: 1px solid #000000;
        box-sizing: border-box;
        border-radius: 5px;
        white-space: pre-wrap;


        &__Small {
            background: #FFFFFF;
            border: 1px solid #000000;
            box-sizing: border-box;
            border-radius: 5px;
        }

        &--Div {
            margin-top: 25px;
        }

        &--Label {
            font-weight: bold;
            font-size: 12px;
            line-height: 14px;
            margin-bottom: 3px;
            width: 100%;
        }
    }
}

.ViewPrograms {
    padding: 20px;
    padding-left: 40px;
}

.Flex-Margin {
    margin-left: 10px;
}

.Select--FullWidth {
    width: 100%;
}

@media only screen and (max-width: 600px) {
    .AddProgram {
        padding: 10px;
        padding-left: 10px;
        width: 100%;

        &--Subtitle {
            margin-bottom: 25px;
        }

        &--Input {
            &__Small {
                max-width: 100%;
            }
        }
    }

    .ViewPrograms {
        padding: 10px;
        padding-left: 10px;
    }
}