.Gallery-Card-Style {
    width: 400px;
    height: 250px;
    object-fit: cover;
    object-position: center;
}

.Gallery-Card-Image-Style {
    width: 100%;
    height: 250px;
    object-fit: cover;
    object-position: center;
}