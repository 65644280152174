.CardButton-Div {
    padding-top: 70px;
    padding-bottom: 70px;
    background-color: white;
    width: 100%;
    text-align: center;
    border-radius: 10px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

@media only screen and (max-width: 576px) {
    .CardButton-Div {
        padding-top: 40px;
        padding-bottom: 40px;
    }
}