.ViewLiveClasses-Row {
    margin-top: 20px;
}

.ViewLiveClasses-Name-Row {
    margin-top: 20px;
    margin-bottom: 10px;
}

.ViewLiveClasses-Sub-Row {
    margin-top: 10px;
    margin-bottom: 10px;
}