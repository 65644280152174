.SectionOrder-Selection {
    width: 100%;
    text-align: center;
    padding: 30px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    font-size: 23px;
    font-weight: 600;
    font-family: "Open Sans";
    height: 100%;
}

.SectionOrder-Selection:hover {
    background: #F8F8F8;
    cursor:pointer;
}

.SectionOrder-Selection-Selected {
    width: 100%;
    text-align: center;
    padding: 30px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    font-size: 23px;
    font-weight: 600;
    font-family: "Open Sans";
    height: 100%;
    background: #FFD43D;
    cursor: pointer;
}