.Service1-Image {
    width: 100%;
    height: 100%;
    max-height: 250px;
    object-position: center;
    object-fit: cover;
    border-radius: 5px;
}

.Service1-Title {
    font-style: normal;
    font-weight: 600;
    font-size: 23px;
    line-height: 30px;
    color: #000000;
}

.Service1-Paragraph {
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 24px;
    color: #000000;
}

.Service1-Div {
    padding: 20px;
    font-family: "Open Sans";
}

.Service1-Image-Div {
    padding: 10px;
}

.Service-Button {
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 8px;
    padding-bottom: 8px;
    background-color: black;
    color: white;
    border-radius: 10px;
}

.Service-Button:hover {
    color: white;
    opacity: 60%;
}