.Navbar1--Container {

}

.Navbar1--Upper--Title {
    font-weight: 600;
    font-size: 24px;
    margin-bottom: 0px;
    margin-left: 5px;
}

.Navbar1--Upper {
    padding: 10px;
    display: flex;
    justify-content: space-between;
}

.Navbar1--Lower {
    background-color: #e9e9e9;
    padding: 10px;
}

.Navbar1--SocialMediaDiv {
    margin: 3px;
}