.H1 {
    font-size: 30px;
    font-weight: 300;
    letter-spacing: 2px;
}

.H1-Highlight {
    background: linear-gradient(to top, #F7B733 30%, transparent 30%);
}

.H2 {
    font-size: 25px;
    font-weight: 300;
    letter-spacing: 2px;
}

.H2-Highlight {
    background: linear-gradient(to top, #F7B733 20%, transparent 20%);
}

.H3 {
    font-size: 18px;
    font-weight: 300;
    letter-spacing: 2px;
}

.H3-Highlight {
    background: linear-gradient(to top, #F7B733 20%, transparent 20%);
}