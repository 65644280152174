.Banner2--Container {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
}

.Banner2-ImageDiv {
    width: 50%;
    height: 80vh;
    display: inline-block;
}

.Banner2--Image {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.Banner2--TextContainer {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: inline-block;
    padding: 40px;
}

.Banner2--Title {
    font-style: normal;
    font-weight: 600;
    font-size: 50px;
    margin-bottom: 20px;
}

.Banner2--SubTitle {
    font-style: normal;
    font-weight: 300;
    font-size: 30px;
    margin-bottom: 20px;
}

.Banner2--Contact {
    background: #000000;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    font-style: italic;
    font-weight: 600;
    font-size: 18px;
    border: none;
    color: white;
    padding: 8px 25px 8px 25px;
}
.Banner2--Contact:hover {
    opacity: 70%;
    cursor: pointer;
}

@media only screen and (max-width: 600px) {
    .Banner2-ImageDiv {
        width: 100%;
        height: 50vh;
    }

    .Banner2--TextContainer {
        width: 100%;
        padding: 20px;
        min-height: 40vh;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .Banner2--Title {
        font-size: 30px;
        margin-bottom: 10px;
        width: 100%;
    }

    .Banner2--SubTitle {
        font-size: 18px;
        margin-bottom: 10px;
        width: 100%;
    }

    .Banner2--Contact {
        font-size: 15px;
        padding: 8px 25px 8px 25px;
        margin-right: auto;
    }
}