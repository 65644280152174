.Upload {
    &--Button {
        height: 60px;
        width: 60px;
        border-radius: 5px;
        border: 1px solid #000000;
        box-sizing: border-box;
    }

    &--File {
        border-radius: 3px;
        border-bottom: 1px solid #000000;
        box-sizing: border-box;
        padding-top: 10px;
        padding-bottom: 10px;
        margin-top: 20px;
    }

    &--AddDetails {
        margin-right: 8px;

        &--TextArea {
            white-space: pre-wrap;
        }
    }


}